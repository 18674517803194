import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { courseIdToPrettyCourseId } from "../../utils/coursePrint";
import { DiscountCodeStatus } from "../initiatePayment";
import VippsButton from "../VippsButton";
import DiscountToggle from "./DiscountToggle";
import GiftToggle from "./GiftToggle";
import PresentCheckoutPage from "./presentCheckout";

const PurpleColorTextField = styled(TextField)`
  & label.Mui-focused {
    color: #d2cafb;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #d2cafb;
    }
  }
`;

interface CheckoutPageProps {
  courseId: string;
  price: number;
  discountCodeStatus: DiscountCodeStatus | "loading" | undefined;
  isOriginallyPresent: boolean;
  discount: number;
  recruiterDiscount: number;
  discountCodeDiscount: number;
  needLogin?: boolean;

  onSubmit: () => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  onDiscountCodeChange: (discountCode: string) => void;
  onDiscountCodeSubmit: () => Promise<void>;
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({
  courseId,
  price,
  discount,
  recruiterDiscount,
  discountCodeDiscount,
  discountCodeStatus,
  isOriginallyPresent,
  needLogin,
  onSubmit,
  onPhoneNumberChange,
  onDiscountCodeChange,
  onDiscountCodeSubmit,
}) => {
  const prettyCourseId = courseIdToPrettyCourseId[courseId]
    ? courseIdToPrettyCourseId[courseId]
    : courseId;
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [discountCode, setDiscountCode] = useState<string>();
  const validatePhoneNumber = (phoneNumber?: string) => {
    return (
      phoneNumber && phoneNumber.length === 8 && !isNaN(Number(phoneNumber))
    );
  };
  const [isPresent, setIsPresent] = useState<boolean>(false);
  const [discountOpen, setDiscountOpen] = useState<boolean>(false);
  const [loadingPrices, setLoadingPrices] = useState<boolean>(
    discountCodeStatus === "loading"
  );
  const numberIsValid = validatePhoneNumber(phoneNumber);

  if (isOriginallyPresent) {
    return (
      <PresentCheckoutPage
        courseId={courseId}
        price={price}
        discount={discount}
        onSubmit={onSubmit}
        onPhoneNumberChange={onPhoneNumberChange}
      />
    );
  }
  return (
    <Card
      sx={{
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        padding={4}
        sx={{
          overflowY: "auto",
          maxHeight: "98%",
          overflowX: "hidden",
        }}
      >
        <Stack direction={"row"} width={"100%"} paddingX={6}>
          <Typography variant="h4">Oppsumering av ordre</Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">Kurs:</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6">Pris:</Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">{prettyCourseId}</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              {price} kr
            </Typography>
          </Stack>
        </Stack>
        {discount > 0 && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Rabatt</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                -{discount} kr
              </Typography>
            </Stack>
          </Stack>
        )}
        {recruiterDiscount > 0 && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Ververabatt</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                -{recruiterDiscount} kr
              </Typography>
            </Stack>
          </Stack>
        )}
        {discountCodeDiscount > 0 && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Rabattkode</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                -{discountCodeDiscount} kr
              </Typography>
            </Stack>
          </Stack>
        )}
        <Divider orientation="horizontal" flexItem />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">Total:</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              {price -
                (discount ?? 0) -
                (recruiterDiscount ?? 0) -
                (discountCodeDiscount ?? 0)}{" "}
              kr
            </Typography>
          </Stack>
        </Stack>
        <DiscountToggle
          isDiscountOpen={discountOpen}
          onIsDiscountOpenChange={setDiscountOpen}
        />
        {discountOpen && (
          <Stack direction="column" width={"100%"} spacing={1}>
            <Stack direction="row" spacing={2} width={"100%"}>
              <PurpleColorTextField
                id="discountCode"
                label="Rabattkode"
                value={discountCode}
                placeholder="feks: ABCDEF"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setDiscountCode(e.target.value);
                  onDiscountCodeChange(e.target.value);
                }}
                size="small"
              />
              <LoadingButton
                variant="contained"
                style={{ backgroundColor: "#c2baeb" }}
                onClick={() => {
                  if (!discountCode) {
                    return;
                  }
                  setLoadingPrices(true);
                  onDiscountCodeSubmit().then(() => {
                    setLoadingPrices(false);
                  });
                }}
                loading={loadingPrices}
              >
                Bruk kode
              </LoadingButton>
            </Stack>

            {discountCodeStatus === "INVALID" && (
              <Alert severity="error">Rabattkoden er ikke gyldig</Alert>
            )}
            {discountCodeStatus === "ALREADY_RECRUITED" && (
              <Alert severity="warning">Ververabatt er allerede lagt til</Alert>
            )}
          </Stack>
        )}
        <GiftToggle isPresent={isPresent} onIsPresentChange={setIsPresent} />

        {isPresent ? (
          <>
            <Stack direction={"column"} maxWidth={"500px"}>
              <Typography variant="subtitle1">
                Fyll inn telefonnummeret (8 siffer) til den som skal få kurset i
                gave
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <PurpleColorTextField
                id="phoneNumber"
                label="Telefonnummer"
                value={phoneNumber}
                placeholder="feks: 12345678"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setPhoneNumber(e.target.value)}
                size="small"
              />
              <Tooltip
                title={
                  numberIsValid ? (
                    ""
                  ) : (
                    <Typography>
                      Fyll inn et gyldig telefonnummer for å fortsette
                    </Typography>
                  )
                }
              >
                <span>
                  <VippsButton
                    disabled={!numberIsValid}
                    onClick={() => {
                      if (!phoneNumber) {
                        return;
                      }
                      onPhoneNumberChange(phoneNumber);
                      onSubmit();
                    }}
                  />
                </span>
              </Tooltip>
            </Stack>
          </>
        ) : (
          <VippsButton
            onClick={() => {
              onSubmit();
            }}
          />
        )}
      </Stack>
    </Card>
  );
};

export default CheckoutPage;
