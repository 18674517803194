import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { Mixpanel } from "../../../utils/mixpanel";
import { DiscountCodeStatus } from "../../initiatePayment";
import VippsButton from "../../VippsButton";
import LoginRequiredCheckoutCard from "./LoginRequiredCheckoutCard";
import PriceBar from "./PriceBar";

const PurpleColorTextField = styled(TextField)`
  & label.Mui-focused {
    color: #d2cafb;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #d2cafb;
    }
  }
`;

interface CheckoutPageProps {
  courseId: string;
  price: number;
  discountCodeStatus: DiscountCodeStatus | "loading" | undefined;
  isPresent: boolean;
  discount: number;
  recruiterDiscount: number;
  discountCodeDiscount: number;
  phoneNumber?: string;
  needLogin?: boolean;

  onSubmit: () => void;
  onDiscountCodeSubmit: (discountCode: string) => Promise<void>;
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({
  courseId,
  price,
  discount,
  recruiterDiscount,
  discountCodeDiscount,
  discountCodeStatus,
  isPresent,
  phoneNumber,
  needLogin,
  onSubmit,
  onDiscountCodeSubmit,
}) => {
  const [discountCode, setDiscountCode] = useState<string>();
  const validatePhoneNumber = (phoneNumber?: string) => {
    return (
      phoneNumber && phoneNumber.length === 8 && !isNaN(Number(phoneNumber))
    );
  };
  const [loadingPrices, setLoadingPrices] = useState<boolean>(
    discountCodeStatus === "loading"
  );
  const numberIsValid = validatePhoneNumber(phoneNumber);

  if (needLogin) {
    return (
      <LoginRequiredCheckoutCard
        price={price}
        discount={discount}
        onSubmit={onSubmit}
      />
    );
  }
  return (
    <Box
      sx={{
        width: "90%",
        height: "100%",
        borderRadius: 2,
        backgroundColor: "#F0F1F3",
        padding: 2,
      }}
    >
      <Stack
        direction="column"
        justifyContent={"space-between"}
        height={"100%"}
        spacing={3}
      >
        <Stack direction={"column"} width={"100%"} gap={3}>
          <Stack direction={"column"} width="100%" gap={1}>
            <PriceBar text="Pris" price={price} negative={false} />
            {discount > 0 && (
              <PriceBar text="Rabatt" price={discount} negative={true} />
            )}
            {recruiterDiscount > 0 && (
              <PriceBar
                text="Ververabatt"
                price={recruiterDiscount}
                negative={true}
              />
            )}
            {discountCodeDiscount > 0 && (
              <PriceBar
                text="Rabattkode"
                price={discountCodeDiscount}
                negative={true}
              />
            )}
            <Divider orientation="horizontal" flexItem />
            <PriceBar
              text="Total"
              price={
                price -
                (discount ?? 0) -
                (recruiterDiscount ?? 0) -
                (discountCodeDiscount ?? 0)
              }
              negative={false}
            />
          </Stack>
          <Stack direction="column" width={"100%"} spacing={3}>
            <Stack
              direction="row"
              spacing={2}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              maxHeight={"40px"}
            >
              <PurpleColorTextField
                id="discountCode"
                label="Rabattkode"
                value={discountCode}
                placeholder="feks: ABCDEF"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setDiscountCode(e.target.value);
                }}
                size="small"
                sx={{
                  fontSize: "12",
                  backgroundColor: "white",
                  width: "100%",
                }}
              />
              <LoadingButton
                variant="contained"
                style={{
                  backgroundColor: "#c2baeb",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  marginBottom: "1px",
                }}
                onClick={() => {
                  if (!discountCode) {
                    return;
                  }
                  setLoadingPrices(true);
                  onDiscountCodeSubmit(discountCode).then(() => {
                    setLoadingPrices(false);
                  });
                  Mixpanel.track("Discount code applied", {
                    courseId,
                    discountCode,
                  });
                }}
                loading={loadingPrices}
              >
                Bruk kode
              </LoadingButton>
            </Stack>

            {discountCodeStatus === "INVALID" && (
              <Alert severity="error">Rabattkoden er ikke gyldig</Alert>
            )}
            {discountCodeStatus === "ALREADY_RECRUITED" && (
              <Alert severity="warning">Ververabatt er allerede lagt til</Alert>
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"} justifyContent={"center"} width={"100%"}>
          {isPresent ? (
            <Tooltip
              title={
                numberIsValid ? (
                  ""
                ) : (
                  <Typography>
                    Fyll inn et gyldig telefonnummer for å fortsette
                  </Typography>
                )
              }
            >
              <span>
                <VippsButton
                  disabled={!numberIsValid}
                  onClick={() => {
                    if (!phoneNumber) {
                      return;
                    }
                    onSubmit();
                  }}
                />
              </span>
            </Tooltip>
          ) : (
            <VippsButton
              onClick={() => {
                onSubmit();
              }}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CheckoutPage;
