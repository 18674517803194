import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { courseIdToPrettyCourseId } from "../../../utils/coursePrint";
import { DiscountCodeStatus } from "../../initiatePayment";
import CheckoutCardOneTime from "../CheckoutCardOneTime";
import { courseIdToImages } from "../courseIdToImages";

export type AgreementPrice = {
  recurringPrice: number;
  discountRecurring: number;
  initialPrice: number;
  initialDiscount: number;
  initialRecruiterDiscount: number;
  initialDiscountCodeDiscount: number;
  initialDiscountCodeStatus: DiscountCodeStatus | "loading" | undefined;
};

interface CheckoutRecurringProps {
  courseId: string;
  email?: string;
  onSubmit: () => void;
  onDiscountCodeSubmit: (discountCode: string) => Promise<void>;
  price: AgreementPrice;
}

const CheckoutRecurring: React.FC<CheckoutRecurringProps> = ({
  courseId,
  email,
  price,
  onSubmit,
  onDiscountCodeSubmit,
}) => {
  const prettyCourseId = courseIdToPrettyCourseId[courseId];
  const initialPriceWithDiscount =
    price.initialPrice -
    price.initialDiscount -
    price.initialDiscountCodeDiscount -
    price.initialRecruiterDiscount;
  return (
    <Stack direction={"row"} sx={{ width: "100%", minHeight: "330px" }}>
      <Stack direction={"column"} sx={{ width: "50%" }}>
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 180,
              width: 210,
              marginLeft: -3,
              marginTop: -2,
            }}
            src={courseIdToImages[courseId]}
          />
          <Stack direction={"column"} sx={{ width: "60%" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginLeft: -4 }}
            >
              {prettyCourseId}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginLeft: -4 }}
            >{`30 dagers tilgang - ingen binding`}</Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            backgroundColor: "#FFD43B",
            maxWidth: "87%",
            padding: 1,
            borderRadius: 2,
            marginLeft: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontSize: 16 }}
          >{`Kom i gang med kurset for kun ${initialPriceWithDiscount} ${
            initialPriceWithDiscount === 1 ? "krone" : "kroner"
          }!`}</Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`Ved å kjøpe abonnement på ${prettyCourseId} får du tilgang til
            kurset i 30 dager. Abonnementet fornyes automatisk hver 30. dag for ${
              price.recurringPrice - price.discountRecurring
            } kr inntil du sier det opp.`}
          </Typography>
        </Box>
      </Stack>
      <Stack direction={"column"} sx={{ width: "50%", paddingY: 1 }}>
        <CheckoutCardOneTime
          courseId={courseId}
          price={price.initialPrice}
          onSubmit={onSubmit}
          onDiscountCodeSubmit={onDiscountCodeSubmit}
          discount={price.initialDiscount}
          recruiterDiscount={price.initialRecruiterDiscount}
          discountCodeDiscount={price.initialDiscountCodeDiscount}
          discountCodeStatus={price.initialDiscountCodeStatus}
          isPresent={false}
          needLogin={!email}
        />
      </Stack>
    </Stack>
  );
};

export default CheckoutRecurring;
