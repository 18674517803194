import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";

import { courseIdToPrettyCourseId } from "../../../utils/coursePrint";
import { DiscountCodeStatus } from "../../initiatePayment";
import CheckoutCardOneTime from "../CheckoutCardOneTime";
import { courseIdToImages } from "../courseIdToImages";

export type AgreementPrice = {
  recurringPrice: number;
  discountRecurring: number;
  initialPrice: number;
  initialDiscount: number;
  initialRecruiterDiscount: number;
  initialDiscountCodeDiscount: number;
  initialDiscountCodeStatus: DiscountCodeStatus;
};

interface CheckoutMobileProps {
  courseId: string;
  price: AgreementPrice;
  email?: string;
  onSubmit: () => void;
  onDiscountCodeSubmit: (discountCode: string) => Promise<void>;
}

const CheckoutMobile: React.FC<CheckoutMobileProps> = ({
  courseId,
  price,
  email,
  onSubmit,
  onDiscountCodeSubmit,
}) => {
  const prettyCourseId = courseIdToPrettyCourseId[courseId];
  const initialPriceWithDiscount =
    price.initialPrice -
    price.initialDiscount -
    price.initialDiscountCodeDiscount -
    price.initialRecruiterDiscount;
  return (
    <Card
      sx={{
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: "92%",
        paddingX: 0,
      }}
    >
      <CardContent>
        <Stack direction={"column"} sx={{ width: "100%" }} gap={2}>
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 180,
                width: 210,
                marginLeft: -3,
                marginTop: -2,
              }}
              src={courseIdToImages[courseId]}
            />
            <Stack direction={"column"} sx={{ width: "60%" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginLeft: -4 }}
              >
                {prettyCourseId}
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginLeft: -4 }}
              >{`30 dagers tilgang - ingen binding`}</Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              backgroundColor: "#FFD43B",
              maxWidth: "94%",
              padding: 1,
              borderRadius: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", fontSize: 14 }}
            >{`Kom i gang med kurset for kun ${initialPriceWithDiscount} ${
              initialPriceWithDiscount === 1 ? "krone" : "kroner"
            }!`}</Typography>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {`Ved å kjøpe abonnement på ${prettyCourseId} får du tilgang til
            kurset i 30 dager. Abonnementet fornyes automatisk hver 30. dag for ${
              price.recurringPrice - price.discountRecurring
            } kr inntil du sier det opp.`}
            </Typography>
          </Box>
          <CheckoutCardOneTime
            courseId={courseId}
            discount={price.initialDiscount}
            recruiterDiscount={price.initialRecruiterDiscount}
            discountCodeDiscount={price.initialDiscountCodeDiscount}
            discountCodeStatus={price.initialDiscountCodeStatus}
            price={price.initialPrice}
            onSubmit={onSubmit}
            onDiscountCodeSubmit={onDiscountCodeSubmit}
            needLogin={!email}
            isPresent={false}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CheckoutMobile;
